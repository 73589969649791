import { defineStore } from "pinia";

interface modalOptions {
  title: string;
  image?: string;
  summary?: string;
  hideCloseButton?: boolean;
  duration?: number;
  button?: {
    text: string;
    action: () => void;
  };
}

export const useModalsStore = defineStore("modals", {
  state: () => {
    return {
      modals: [] as (modalOptions & { id: string })[],
    };
  },
  actions: {
    add(modal: modalOptions) {
      const id = Math.random().toString(36).substring(2, 15);
      const len = this.modals.push({ ...modal, id });
      if (modal.duration) setTimeout(() => this.remove(id), modal.duration);
      return this.modals[len - 1];
    },
    remove(id: string) {
      const index = this.modals.findIndex((modal) => modal.id === id);
      if (index > -1) this.modals.splice(index, 1);
    },
  },
  getters: {
    activeModal: (state) => state.modals[0],
  },
});
