//DEVELOP

const BRANCH = "develop";
export const environment = {
  RELEASE: {
    VERSION: process.env.VUE_APP_VERSION,
    CREATED: process.env.VUE_APP_TIMESTAMP,
    BUILD: process.env.VUE_APP_BUILD,
  },
  production: false,
  branch: BRANCH,
  storagePrefix: process.env.NODE_ENV === "development" ? `b2b.develop` : "b2b",
  baseAPIUrl: "https://us-central1-back2better-38631.cloudfunctions.net", //TODO: Legacy - Remove
  appSecretKey: "64B21A21823GD512",
  authntoken: "",
  STRIPE_PUBLISHABLE_KEY: process.env.VUE_APP_STRIPE_KEY,
  serverToken: process.env.VUE_APP_API_KEY,
  strapiUrl: process.env.VUE_APP_STRAPI_API_URL,
  mapboxToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
};
