import { environment } from "@/../environment";
import { defineStore } from "pinia";
import { Storage } from "@ionic/storage";
import { ref, Ref } from "vue";
import { StrapiUser } from "../types/user";
import { api } from "../core/_helpers/api";
import { AxiosResponse } from "axios";
import io, { Socket } from "socket.io-client";

interface UserStore {
  storeLoaded: Ref<boolean>;
  token: Ref<string | null>;
  user: Ref<StrapiUser | null>;
  profile: Ref<ProviderProfile | null>;
  pin: Ref<string | null>;
  pinAsk: Ref<Date | null>;
  fingerprint: Ref<boolean | null>;
  usePassword: Ref<boolean | null>;
}

interface injuries {
  id: number;
  attributes: {
    title: string;
  };
}

export type weekday =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export interface ProviderProfile {
  providerType: "Physiotherapy";
  yearsQualified: string;
  provider?: StrapiUser;
  providerCategories?: {
    data: injuries[];
  };
  id: number;
  about: string;
  documents: any;
  availabilityDay: {
    day: weekday;
    hours: string[];
  }[];
  waitForReview: boolean | null;
  availabilityRadius: number;
  isAvailable: boolean;
  autoAccept: boolean;
  postCode: string;
  fixedServiceArea: boolean;
  onTheGoServiceArea: boolean;
  lastLat: number;
  lastLng: number;
}

export interface HealthFund {
  id: number;
  name: string;
  providerNumber: number;
}

export interface FinancialInformation {
  abn: string;
  businessName: string;
  displayName: string;
  businessAddress: string;
  registeredForGST: boolean;
  bankingInformation: {
    id: number;
    BSB: number;
    accountNumber: number;
  }[];
  healthFund: HealthFund[];
}

const getSteps = (state) => {
  return {
    1:
      !!state.user?.firstName &&
      !!state.user?.profileImg &&
      !!state.user?.lastName,
    2:
      !!state.user?.mobileNumber &&
      !!state.user?.addresses &&
      state.user?.addresses.length > 0 &&
      !!state.profile?.availabilityRadius,
    3:
      !!state.profile?.about &&
      !!state.profile?.yearsQualified &&
      !!state?.profile?.providerCategories?.data &&
      state.profile.providerCategories.data.length > 0,
    4:
      state.profile?.availabilityDay &&
      state.profile?.availabilityDay.length > 0,
   
    5:
      !!state.financialInformation &&
      !!state.financialInformation.abn &&
      !!state.financialInformation.businessName &&
      !!state.financialInformation.displayName &&
      !!state.financialInformation.businessAddress &&
      !!state.financialInformation.bankingInformation &&
      state.financialInformation.bankingInformation.length > 0 &&
      !!state.financialInformation.bankingInformation[0].BSB &&
      !!state.financialInformation.bankingInformation[0].accountNumber,
    6:
      !!state.user?.agreedToTerms === true,
    7:
      !!state.profile?.documents &&
      !!state.profile.documents.licenseNumber &&
      state.profile.documents.documents.length > 0,

  };
};

const authStore = new Storage();
export const useAuthStore = defineStore("auth", {
  state: () => {
    const token = ref(null) as UserStore["token"];
    const user = ref(null) as UserStore["user"];
    const storeLoaded = ref(false) as UserStore["storeLoaded"];
    const socket = ref(null) as Ref<Socket | null>;
    const pin = ref(null) as Ref<string | null>;
    const pinAsk = ref(null) as Ref<Date | null>;
    const fingerprint = ref(null) as Ref<boolean | null>;
    const usePassword = ref(null) as Ref<boolean | null>;

    (async () => {
      await authStore.create();
      const t = await authStore.get(`${environment.storagePrefix}.jwt`);
      if (t) {
        token.value = t;
        try {
          const response = await api.get<StrapiUser>("api/users/me", {
            params: {
              populate: ["role", "profileImg", "addresses"],
            },
          });
          user.value = response.data;
          if (!user.value.extradata) {
            user.value.extradata = {};
          }
          storeLoaded.value = true;
        } catch (e) {
          console.log(e);
        }
        socket.value = io(environment.strapiUrl, {
          autoConnect: false,
          auth: {
            token: token.value,
          },
        });
        socket.value.connect();
      } else {
        if (socket.value?.connected) {
          socket.value.disconnect();
        }
      }
      pin.value = await authStore.get(`${environment.storagePrefix}.pin`);
      pinAsk.value = await authStore.get(`${environment.storagePrefix}.pinAsk`);
      fingerprint.value = await authStore.get(
        `${environment.storagePrefix}.fingerprint`
      );
      usePassword.value =
        (await authStore.get(`${environment.storagePrefix}.usePassword`)) ||
        false;
      storeLoaded.value = true;
    })();

    return {
      storeLoaded,
      token,
      user,
      profile: ref(null) as Ref<ProviderProfile | null>,
      financialInformation: ref(null) as Ref<FinancialInformation | null>,
      socket,
      pin,
      pinAsk,
      fingerprint,
      usePassword,
    };
  },
  actions: {
    setToken(token: string, user: StrapiUser | null = null) {
      this.storeLoaded = false;
      return new Promise((resolve) => {
        authStore.set(`${environment.storagePrefix}.jwt`, token).then(() => {
          this.token = token;
          this.storeLoaded = true;
          if (this.socket?.connected) {
            this.socket.disconnect();
          }
          this.socket = io(environment.strapiUrl, {
            autoConnect: false,
            auth: {
              token: token,
            },
          });
          this.socket.connect();
          if (user) {
            this.user = user;
            return resolve({ token, user });
          }
          api
            .get("api/users/me", {
              params: {
                populate: ["role", "profileImg", "addresses"],
              },
            })
            .then((response: AxiosResponse<StrapiUser>) => {
              this.user = response.data;
              if (!this.user.extradata) {
                this.user.extradata = {};
              }
              resolve({ token, user: response.data });
            });
        });
      });
    },
    async fetchProfile() {
      try {
        const profile = await api.get<{
          data: { id: number; attributes: ProviderProfile };
        }>("/api/profile/my");
        this.profile = profile.data.data.attributes;
        this.profile.id = profile.data.data.id;
      } catch (e) {
        console.log("1", e)
        this.profile = null;
      }
      try {
        this.financialInformation = (
          await api.get("/api/v2/financial-informations/my")
        ).data;
      } catch (e) {
        console.log("2", e)
        //
      }
      return this.profile;
    },
    reset() {
      authStore.clear();
      this.token = null;
      this.user = null;
      this.profile = null;
      if (this.socket?.connected) {
        this.socket.disconnect();
      }
    },
    async setExtraData(key: string, value: any) {
      if (!this.user) {
        return;
      }
      if (!this.user.extradata) {
        this.user.extradata = {};
      }
      this.user.extradata[key] = value;
      const response = await api.put(`/api/user/me`, {
        extradata: this.user.extradata,
      });
      this.user.extradata = response.data.extradata;
      return this.user.extradata?.[key];
    },
    setPin(pin: string | false) {
      if (pin === false) {
        authStore.remove(`${environment.storagePrefix}.pin`);
        this.pin = null;
      } else {
        authStore.set(`${environment.storagePrefix}.pin`, pin);
        this.pin = pin;
      }
      this.setPinAsk();
    },
    setPinAsk() {
      const nextAsk = new Date();
      nextAsk.setMinutes(nextAsk.getMinutes() + 15);
      authStore.set(`${environment.storagePrefix}.pinAsk`, nextAsk);
      this.pinAsk = nextAsk;
    },
    async setFingerprint(enabled: boolean) {
      await authStore.set(`${environment.storagePrefix}.fingerprint`, enabled);
      this.fingerprint = enabled;
    },
    async setUsePassword(enabled: boolean) {
      await authStore.set(`${environment.storagePrefix}.usePassword`, enabled);
      this.usePassword = enabled;
      this.setPinAsk();
    },
  },
  getters: {
    ready: (state) => {
      if (state.storeLoaded) return Promise.resolve(true);
      //waint until store is loaded
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (state.storeLoaded) {
            clearInterval(interval);
            resolve(true);
          }
        }, 100);
      });
    },
    extradata: (state) => (key: string) => {
      return state.user?.extradata?.[key];
    },
    steps: getSteps,
    isRegistered: (state) => {
      const steps = getSteps(state);
      return Object.keys(steps).every((key) => steps[key]);
    },
    isProfileSubmitted: (state) => {
      return state.profile?.waitForReview !== null;
    },
  },
});
