import { useAppointmentsStore } from "@/app/stores/appointments";
import { storeToRefs } from "pinia";
import { ref } from "vue";

export default async (to: any, from: any, next: any) => {
  const appointmentsStore = useAppointmentsStore();
  const { sessionInfo } = storeToRefs(appointmentsStore);

  const sessionId = ref(+to.params.id);
  if (sessionInfo.value.appointmentData.id != sessionId.value) {
    await appointmentsStore.getPsfs();
    const el = await appointmentsStore.fetchAppointment(sessionId.value, true);
    sessionInfo.value.appointmentData = el;
    if (!el.sessionStartTime) {
      await appointmentsStore.startSession(sessionId.value);
    }
  }
  if(appointmentsStore.exerciseTypes.length === 0) {
    await appointmentsStore.getExerciseTypes();
  }
  if(appointmentsStore.locations.length === 0) {
    await appointmentsStore.getLocations();
  }
  next();
};
