import DashboardView from "../app/views/Layouts/DashboardView.vue";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/app/stores/auth";
import sessionMiddleware from "@/app/middlewares/session";

async function requireAuth(to: any, from: any, next: any) {
  const authStore = useAuthStore();
  await authStore.ready;

  const token: string | null = authStore.token;
  if (token) {
    if (!authStore.profile) await authStore.fetchProfile();
    if (!authStore.profile && !to.path.includes("auth/register")) {
      return next("/auth/register/step/1");
    }
    if(!authStore.isRegistered && to.path.includes("auth/register") && from?.path.includes("auth/register") ) return next();
    for (const step in authStore.steps) {
      if (
        !authStore.steps[step] &&
        !to.path.includes(`auth/register/step`)
      ) {
        return next(`/auth/register/step/${step}`);
      } else if (!authStore.steps[step]) return next();
    }
    const allStepsCompleted = Object.values(authStore.steps).every(
      (step) => step
    );

    if (
      authStore.isProfileSubmitted &&
      allStepsCompleted &&
      to.path.includes("auth/register")
    ) {
      return next("/dashboard/home");
    } else if (
      !authStore.isProfileSubmitted &&
      !allStepsCompleted &&
      !to.path.includes("auth/register")
    ) {
      return next("/auth/register/step/7");
    }
    return next();
  } else {
    return next("/auth/login");
  }
}

//require withouth auth
async function requireNoAuth(to: any, from: any, next: any) {
  const authStore = useAuthStore();
  await authStore.ready;

  const token: string | null = authStore.token;
  if (token) {
    next("/dashboard/home");
  } else {
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/dashboard/home",
  },
  {
    path: "/test",
    component: () => import("@/app/views/Test.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/dashboard/home",
  },
  {
    path: "/dashboard",
    component: DashboardView,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        redirect: "/dashboard/home",
      },
      {
        path: "home",
        name: "Home Page",
        component: () => import("@/app/views/Pages/HomePage.vue"),
      },
      {
        path: "appointments",
        name: "Appointments",
        component: () => import("@/app/views/Pages/AppointmentList.vue"),
      },
      {
        path: "patients",
        name: "Patients",
        component: () => import("@/app/views/_archived/Tab3.vue"),
      },
      {
        path: "exercise",
        name: "Exercise",
        component: () => import("@/app/views/Exercise/Page.vue"),
      },
      {
        path: "referrals",
        name: "Referrals",
        component: () => import("@/app/views/_archived/Tab4.vue"),
      },
      {
        path: "letters",
        name: "Letters",
        component: () => import("@/app/views/_archived/Tab4.vue"),
      },
      {
        path: "payment-history",
        name: "Payment History",
        component: () => import("@/app/views/_archived/Tab4.vue"),
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("@/app/views/_archived/Tab4.vue"),
      },
      {
        path: "help",
        name: "Help",
        component: () => import("@/app/views/_archived/Tab4.vue"),
      },
      {
        path: "protocols",
        name: "Protocols",
        component: () => import("@/app/views/_archived/Tab4.vue"),
      },
      {
        path: "messages",
        name: "Messages",
        component: () => import("@/app/views/_archived/Messages.vue"),
      },
      {
        path: "schedule",
        name: "Schedule",
        component: () => import("@/app/views/Pages/Schedule.vue"),
      },
      {
        path: "patients",
        name: "Patients",
        component: () => import("@/app/views/Pages/Patients.vue"),
      },
    ],
  },

  // Appointment Session
  // initial and follow up routes
  {
    path: "/appointment/clinic/notes/:id",
    component: () => import("@/app/views/Session/ClinicalNote.vue"),
    name: "Clinical Notes",
    beforeEnter: [requireAuth, sessionMiddleware],
  },
  {
    path: "/appointment/clinic/recovery/:id",
    component: () => import("@/app/views/Session/RecoveryMarkers.vue"),
    name: "Recovery Markers",
    beforeEnter: [requireAuth, sessionMiddleware],
  },
  {
    path: "/appointment/clinic/discharge/:id",
    component: () => import("@/app/views/Session/Discharge.vue"),
    name: "Discharge",
    beforeEnter: [requireAuth, sessionMiddleware],
  },
  {
    path: "/appointment/clinic/soap/:id",
    component: () => import("@/app/views/Session/SOAP.vue"),
    name: "soap",
    beforeEnter: [requireAuth, sessionMiddleware],
  },
  // Initial Session
  {
    path: "/appointment/clinic/exercise/:id",
    component: () => import("@/app/views/Session/ExercisePrescription.vue"),
    name: "Exercise Plan",
    beforeEnter: [requireAuth, sessionMiddleware],
  },
  // Follow up Session
  {
    path: "/appointment/followup/:id",
    component: () => import("@/app/views/Session/FollowUp.vue"),
    name: "Follow Up",
    beforeEnter: [requireAuth, sessionMiddleware],
  },
  // {
  //   path: `/appointment/followup/:id/exercises`,
  //   component: () => import("@/app/views/Session/FollowUpExercises.vue"),
  //   name: "Follow Up Exercise Plan",
  //   beforeEnter: [requireAuth, sessionMiddleware],
  // },
  // End Appointment Session

  // user
  {
    path: "/user/settings",
    component: () => import("@/app/views/User/SettingsPage.vue"),
    name: "Settings",
    beforeEnter: requireAuth,
  },
  {
    path: "/user/settings/quick-login",
    component: () => import("@/app/views/User/QuckLogin.vue"),
    name: "Quick Login",
    beforeEnter: requireAuth,
  },
  {
    path: "/user/settings/payments",
    component: () => import("@/app/views/User/PaymentsPage.vue"),
    name: "Payments Settings",
    beforeEnter: requireAuth,
  },
  {
    path: "/user/settings/login-history",
    component: () => import("@/app/views/User/LoginHistory.vue"),
    name: "Login History",
    beforeEnter: requireAuth,
  },
  {
    path: "/user/settings/password",
    component: () => import("@/app/views/User/ChangePassword.vue"),
    name: "Change password",
    beforeEnter: requireAuth,
  },
  {
    path: "/user/profile",
    component: () => import("@/app/views/User/ProfilePage.vue"),
    name: "Profile Page",
    beforeEnter: requireAuth,
  },
  // end user

  {
    path: "/message/:userid",
    name: "chat",
    component: () => import("@/app/views/_archived/Chat.vue"),
  },
  // {
  //   path: "/auth/login/:provider",
  //   // component: Login,
  //   component: () => import("@/app/views/authentication/Login.vue"),
  //   beforeEnter: requireNoAuth,
  // },
  // Auth
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/app/views/Layouts/empty-layout.vue"),
    children: [
      {
        path: "",
        name: "auth-redirect",
        redirect: "/auth/register",
        beforeEnter: requireNoAuth,
      },
      {
        path: "login",
        component: () => import("@/app/views/Auth/Login.vue"),
        name: "login",
        beforeEnter: requireNoAuth,
      },
      {
        path: "forgot-password",
        component: () => import("@/app/views/Auth/Forgot.vue"),
        name: "forgot-password",
        beforeEnter: requireNoAuth,
      },
      {
        path: "reset-password",
        component: () => import("@/app/views/Auth/Reset.vue"),
        name: "reset-password",
        beforeEnter: requireNoAuth,
      },
      {
        path: "register",
        component: () => import("@/app/views/Layouts/empty-layout.vue"),
        name: "register",
        children: [
          {
            path: "",
            name: "Register #0",
            component: () => import("@/app/views/Auth/Register/Step0.vue"),
            beforeEnter: requireNoAuth,
          },
          {
            path: "step/1",
            name: "Register #1",
            component: () => import("@/app/views/Auth/Register/Step1.vue"),
            beforeEnter: requireAuth,
          },

          {
            path: "step/2",
            name: "Register #2",
            component: () => import("@/app/views/Auth/Register/Step2.vue"),
            beforeEnter: requireAuth,
          },
          {
            path: "step/3",
            name: "Register #3",
            component: () => import("@/app/views/Auth/Register/Step3.vue"),
            beforeEnter: requireAuth,
          },
          {
            path: "step/4",
            name: "Register #4",
            component: () => import("@/app/views/Auth/Register/Step4.vue"),
            beforeEnter: requireAuth,
          },
          {
            path: "step/5",
            name: "Register #5",
            component: () => import("@/app/views/Auth/Register/Step5.vue"),
            beforeEnter: requireAuth,
          },
          {
            path: "step/6",
            name: "Register #6",
            component: () => import("@/app/views/Auth/Register/Step6.vue"),
            beforeEnter: requireAuth,
          },
          {
            path: "step/7",
            name: "Register #7",
            component: () => import("@/app/views/Auth/Register/Step7.vue"),
            beforeEnter: requireAuth,
          },
          // {
          //   path: "step/8",
          //   name: "Register #8",
          //   component: () => import("@/app/views/Auth/Register/Step8.vue"),
          //   beforeEnter: requireAuth,
          // },
          {
            path: "step/8",
            name: "Register #8",
            component: () => import("@/app/views/Auth/Register/Step8.vue"),
            beforeEnter: requireAuth,
          }
        ],
      },
    ],
  },

  // End Auth
  {
    path: "/mail-verification",
    component: () => import("@/app/views/authentication/MailVerification.vue"),
  },
  {
    path: "/terms-and-conditions",
    component: () => import("@/app/views/Pages/TermsAndConditions.vue"),
  },
  {
    path: "/information",
    component: () => import("@/app/views/Pages/Information.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
