import axios, { AxiosRequestConfig } from "axios";
import { environment } from "@/../environment";
import { useAuthStore } from "@/app/stores/auth";
import qs from "qs";
// import { useAuthStore } from '@/app/stores/auth'


export const api = axios.create({
  baseURL: environment.strapiUrl
})

//add bearer token if it exists on store
api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const authStore = useAuthStore()
  if (authStore.token) {
    config.headers.Authorization = `Bearer ${authStore.token}`
  }
  config.paramsSerializer =  params => {
    return qs.stringify(params)
  }
  return config
});

api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if (error.response?.status === 401) {
    const authStore = useAuthStore()
    authStore.reset()
  }

  return Promise.reject(error);
});