import { defineStore } from "pinia";
import { api } from "../core/_helpers/api";
import { StrapiUser } from "../types/user";
import qs from "qs";

export interface exercise {
  id: number;
  attributes: {
    name: string;
    instructions: string;
    duration: string;
    repeat: string;
    reps: number;
    sets: number;
  };
}

export interface plan {
  id: number;
  attributes: {
    age: string;
    gender: string;
    name: string;
    planExercise: {
      data: exercise[];
    };
    description: string;
  };
}

export interface appointment {
  id: number;
  attributes?: any;
  appointmentTimeStart: string;
  appointmentTimeEnd: string;
  sessionStartTime: string | null;
  sessionEndTime: string | null;
  latitude: number | null;
  longitude: number | null;
  sessionStatus: string | null;
  status:
    | "requested"
    | "scheduled"
    | "in-progress"
    | "declined"
    | "reschedule"
    | "completed"
    | "awaiting-payment"
    | "lapsed";
  isRefund: boolean;
  isSurveyCompleted: boolean;
  price: number | null;
  paymentId: string | null;
  refundId: string | null;
  dateOfInjury: string | null;
  publishedAt: string | null;
  painScale: number | null;
  additionalInfo: string | null;
  notes: string | null;
  patient: StrapiUser;
  provider: StrapiUser;
  appointmentUUID?: string;
  category: {
    id: number;
    title: string;
  };
  patientAddress: {
    id: number;
    addressType: "at home" | "at work" | "somewhere else";
    apartment: string | null;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    unit: string | null;
    parkingType: "driveway" | "off street" | "on street" | "metered";
    stairs: boolean;
    petAnimals: boolean;
  };
  sessionDuration?: number;
  condition?: {
    id: number;
    name: string;
  };
  psfsDetails?: any;
  otherMeasures?: any;
  plan?: any;
  icd_10_region?: any;
  parent?: any;
  Escalation?: any;
  dischargeReason?: any;
  healthCondition?: string;
  clinicNote?: any;
  exercise_report?: {
    data?: {
      id: number;
      attributes: {
        daysToRecord: string[];
        duration: number;
        recordedData: any;
        repeat: number;
        createdAt: string;
        updatedAt: string;
        appointment?: any;
      };
    };
  };
}

interface myAppointmentsResponse {
  results: appointment[];
  pagination: {
    start: number;
    limit: number;
    total: number;
  };
}

export interface sessionDataInterface {
  icd_10_region: any;
  dateOfInjury: string | null;
  condition: {
    id: number;
    name: string;
  } | null;
  painArea: [string, string, string, string] | null;
  painScale: number | null;
  psfsDetails: {
    activity: number;
    difficultyLevel: number;
  }[];
  measureDeails: {
    measure: {
      id: number;
      title: string;
      questions: {
        id: number,
        title: string,
        answers: {
          id: number,
          title: string,
          score: number
        }[]
      }[]
    };
    score: string;
    values: any;
  }[];
  Escalation: any;
}

export interface psfs {
  id: number;
  attributes: {
    title: string;
  };
}

export type proms = psfs & {
  attributes: { min: number; max: number; title?: string };
};

export const useAppointmentsStore = defineStore("appointments", {
  state: () => {
    return {
      requested: [] as appointment[],
      upcoming: [] as appointment[],
      past: [] as appointment[],
      psfsList: [] as psfs[],
      measuresList: [] as proms[],
      exerciseTypes: [] as psfs[],
      locations: [] as psfs[],
      sessionInfo: {
        appointmentData: {} as appointment,
        sessionData: {
          dateOfInjury: null,
          condition: null,
          painArea: null,
          painScale: null,
          psfsDetails: [],
          measureDeails: [],
          icd_10_region: null,
          Escalation: {},
        } as sessionDataInterface,
      },
    };
  },
  actions: {
    async fetchAppointments(
      type: "requested" | "upcoming" | "past" = "requested",
      filters = [1, 2]
    ) {
      const response = await api.get<myAppointmentsResponse>(
        "/api/provider/apointments/my",
        {
          params: {
            type,
            filters: filters.map((f) => (f == 1 ? "initial" : "followup")),
            pagination: {
              limit: -1,
            },
            sort:
              type == "past"
                ? {
                    sessionEndTime: "desc",
                  }
                : {
                    appointmentTimeStart: "asc",
                  },
          },
        }
      );
      this[type] = response.data.results;
      console.log("test", response.data.results)
      return response.data.results;
    },
    async acceptAppointment(
      appointmentId: number,
      fetchUpcoming = true,
      fetchRequested = false
    ) {
      const response = await api.post<appointment>(
        `/api/appointment/accept/${appointmentId}`
      );
      if (fetchUpcoming) await this.fetchAppointments("upcoming");
      if (fetchRequested) await this.fetchAppointments("requested");
      return response.data;
    },
    async cancelAppointment(
      appointmentId: number,
      fetchUpcoming = true,
      fetchRequested = true
    ) {
      const response = await api.post<appointment>(
        `/api/appointment/cancel/${appointmentId}`
      );
      if (fetchUpcoming) await this.fetchAppointments("upcoming");
      if (fetchRequested) await this.fetchAppointments("requested");
      return response.data;
    },
    async rescheduleAppointment(
      appointmentId: number,
      fetchUpcoming = true,
      fetchRequested = false
    ) {
      const response = await api.post<appointment>(
        `/api/appointment/send-reschedule/${appointmentId}`
      );
      if (fetchUpcoming) await this.fetchAppointments("upcoming");
      if (fetchRequested) await this.fetchAppointments("requested");
      return response.data;
    },
    async startSession(
      appointmentId: number,
      fetchUpcoming = false,
      fetchRequested = false
    ) {
      const response = await api.post<appointment>(
        `/api/appointment/start/${appointmentId}`
      );
      // this.sessionInfo.appointmentData = response.data;
      if (fetchUpcoming) await this.fetchAppointments("upcoming");
      if (fetchRequested) await this.fetchAppointments("requested");
      return response.data;
    },
    async fetchAppointment(appointmentId: number, isCurrentSession = false) {
      const response = await api.get(`/api/appointments/${appointmentId}`, {
        params: {
          populate: [
            "patient",
            "provider",
            "category",
            "patientAddress",
            "condition",
            "otherMeasures.measure",
            "psfsDetails.activity",
            "plan",
            "icd_10_region",
            "Escalation",
            "dischargeReason",
            "clinicNote.clinicNote",
            "parent",
            "exercise_report",
          ],
        },
      });

      const appointment = formatStrapiResponse(response);

      //If it is fetch for geting information about current session then we need to set sesseionData values from fetched appointment
      if (isCurrentSession) {
        this.sessionInfo.appointmentData = appointment;
        //Date of injury
        this.sessionInfo.sessionData.dateOfInjury = appointment.dateOfInjury;
        // Condition
        this.sessionInfo.sessionData.condition = appointment.condition
          ? {
              id: appointment.condition.id,
              name: appointment.condition.name,
            }
          : null;
        // icd_10_region
        this.sessionInfo.sessionData.icd_10_region = appointment.icd_10_region
          ? {
              id: appointment.icd_10_region.id,
              name: appointment.icd_10_region.name,
            }
          : null;
        //Pain scale
        this.sessionInfo.sessionData.painScale = appointment.painScale;
        //PSFS details
        this.sessionInfo.sessionData.psfsDetails = appointment.psfsDetails.map(
          (psfs) => {
            return {
              activity: psfs.activity?.data?.id,
              difficultyLevel: psfs.difficultyLevel,
            };
          }
        );
        //Measures
        this.sessionInfo.sessionData.measureDeails =
          appointment.otherMeasures.map((measure) => {
            return {
              measure: measure.measure?.data?.id,
              score: measure.score,
            };
          });
      }

      return appointment;
    },
    async getPsfs() {
      const psfsLIst = await api.get<{ data: psfs[] }>("/api/activities");
      this.psfsList = psfsLIst.data.data;
      return psfsLIst.data.data;
    },
    async getExerciseTypes() {
      const exerciseTypes = await api.get<{ data: psfs[] }>(
        "/api/exercise-types",
        {
          params: {
            pagination: {
              limit: -1,
            },
          },
        }
      );
      this.exerciseTypes = exerciseTypes.data.data;
      return exerciseTypes.data.data;
    },
    async getLocations() {
      const locations = await api.get<{ data: psfs[] }>(
        "/api/exercise-locations",
        {
          params: {
            pagination: {
              limit: -1,
            },
          },
        }
      );
      this.locations = locations.data.data;
      return locations.data.data;
    },
    async findMeasures(q = "") {
      const response = await api.get<{ data: proms[] }>(`/api/measures${q}`);
      this.measuresList = response.data.data;
      return response.data.data;
    },
    async addClinicalNotes() {
      const submitData = {
        dateOfInjury: this.sessionInfo.sessionData.dateOfInjury,
        painScale: this.sessionInfo.sessionData.painScale,
        psfsDetails: this.sessionInfo.sessionData.psfsDetails,
        otherMeasures: this.sessionInfo.sessionData.measureDeails.map(m => {
          return {
            measure: m.measure.id,
            score: +m.score,
            values: m.values,
          }
        }),
        condition: this.sessionInfo.sessionData.condition?.id || null,
        icd_10_region: this.sessionInfo.sessionData.icd_10_region?.id || null,
      } as any;
      if (this.sessionInfo.sessionData.painArea) {
        //upload each of the pain areas
        const locations = ["Front", "Back", "LeftSide", "RightSide"];
        for (const [
          index,
          area,
        ] of this.sessionInfo.sessionData.painArea.entries()) {
          if (!isBase64(area)) continue;
          if (area) {
            const formData = new FormData();
            formData.append(
              "files",
              dataURLtoFile(area, `${locations[index]}.png`)
            );

            const image = await api.post(`/api/upload`, formData);

            submitData[`locationOfPain${locations[index]}`] = image.data[0].id;
          }
        }
      }

      const response = await api.put<{ data: appointment }>(
        `/api/appointment/update-clinic-notes/${this.sessionInfo.appointmentData.id}`,
        {
          data: submitData,
        }
      );
      this.sessionInfo.appointmentData = formatStrapiResponse(response);
      return response.data;
    },
  },
});

const formatStrapiResponse = (response): appointment => {
  const data = {
    id: response.data.data.id,
    ...response.data.data.attributes,
  };
  const patient = {
    id: data.patient.data.id,
    ...data.patient.data.attributes,
  };
  const provider = {
    id: data.provider.data.id,
    ...data.provider.data.attributes,
  };
  const category = {
    id: data.category.data.id,
    ...data.category.data.attributes,
  };
  const patientAddress = {
    id: data.patientAddress?.data?.id,
    ...data.patientAddress?.data?.attributes,
  };
  data.patient = patient;
  data.provider = provider;
  data.category = category;
  data.patientAddress = patientAddress;

  if (data.condition?.data) {
    data.condition = {
      id: data.condition.data.id,
      ...data.condition.data.attributes,
    };
  }
  if (data.icd_10_region?.data) {
    data.icd_10_region = {
      id: data.icd_10_region.data.id,
      ...data.icd_10_region.data.attributes,
    };
  }

  return data;
};

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function isBase64(str: string) {
  if (str) return str.startsWith("data:");
  return false;
}
